<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link to="/" class="d-flex align-center">
            <v-img :src="appLogo" max-height="200px" max-width="200px" alt="logo" contain class="me-3"></v-img>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <!-- <p
            class="text-2xl font-weight-semibold text--primary mb-2"
            style="text-align: center"
          >
            An Interesting Phrase about Palomar
          </p> -->
          <p class="mb-2">Please sign-in to your account and start the adventure</p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <!-- Vue Form Inputs -->
          <v-form ref="loginForm" @submit="submitLogin">
            <!-- END OF ELEMENT Inputs -->
            <v-text-field
              v-model="email"
              outlined
              label="Email"
              class="mb-3"
              :rules="[validators.required, validators.emailValidator]"
              hide-details="auto"
            />
            <!-- END of ELEMENT Inputs -->

            <v-text-field
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Password"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details="auto"
              :rules="[validators.required]"
              @click:append="isPasswordVisible = !isPasswordVisible"
            />

            <div class="d-flex align-center justify-space-between flex-wrap">
              <v-checkbox label="Remember Me" hide-details class="me-3 mt-1"> </v-checkbox>

              <!-- forgot link -->
              <router-link :to="{ name: 'auth-forgot-password' }" class="mt-1"> Forgot Password? </router-link>
            </div>

            <v-alert v-if="errors.length > 0" class="mt-4" color="error" text>
              <p class="font-weight-semibold mb-1">Messages</p>
              <p v-for="(error, i) in errors" :key="i" class="text-sm mb-0">
                {{ error }}
              </p>
            </v-alert>

            <v-btn type="submit" block color="primary" class="mt-6" @click="submitLogin" :disabled="loading">
              <label v-if="!loading">Login</label>
              <v-progress-circular v-if="loading" indeterminate color="amber"></v-progress-circular>
            </v-btn>
          </v-form>
        </v-card-text>

        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <span class="me-2"> New on our platform? </span>
          <router-link :to="{ name: 'auth-register' }"> Create an account </router-link>
        </v-card-text>

        <!-- divider -->
        <v-card-text class="d-flex align-center mt-2">
          <v-divider></v-divider>
          <span class="mx-5">or</span>
          <v-divider></v-divider>
        </v-card-text>

        <!-- social links -->
        <v-card-actions class="d-flex justify-center">
          <v-btn v-for="link in socialLink" :key="link.icon" icon class="ms-1">
            <v-icon :color="$vuetify.theme.dark ? link.colorInDark : link.color">
              {{ link.icon }}
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="190"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import themeConfig from '@themeConfig'
import { mapActions, mapGetters } from 'vuex'
import { required, emailValidator, passwordValidator } from '@core/utils/validation'

const socialLink = [
  {
    icon: mdiFacebook,
    color: '#4267b2',
    colorInDark: '#4267b2',
  },
  {
    icon: mdiTwitter,
    color: '#1da1f2',
    colorInDark: '#1da1f2',
  },
  {
    icon: mdiGithub,
    color: '#272727',
    colorInDark: '#fff',
  },
  {
    icon: mdiGoogle,
    color: '#db4437',
    colorInDark: '#db4437',
  },
]

export default {
  data() {
    return {
      isPasswordVisible: false,
      email: '',
      password: '',
      loading: false,
      validators: {
        required,
        emailValidator,
        passwordValidator,
      },

      // errors
      message: '',
      errors: [],

      // colors
      socialLink,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
  methods: {
    ...mapActions({
      login: 'auth/login',
      getUser: 'auth/getUser',
      autoLogin: 'auth/autoLogin',
    }),
    async submitLogin(e) {
      e.preventDefault()
      this.loading = true

      const isFormValid = this.$refs.loginForm.validate()
      if (!isFormValid) {
        this.loading = false
        return
      }

      const formData = {
        email: this.email,
        password: this.password,
      }

      this.login(formData)
        .then(resp => {
          if (resp && resp.data) {
            const { user, message, errors } = resp.data

            if (user) {
              this.data = user
              this.message = ''
              this.errors = []
              this.getUserById()
            }
          }
        })
        .catch(error => {
          console.log(error)
          if (error.response.data && error.response.data.status === 'error') {
            this.errors = error.response.data.errors
          } else {
            this.errors = null
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    async getUserById() {
      await this.getUser().then(response => {
        localStorage.setItem('userData', JSON.stringify(response.data))
        this.$router.push({ name: 'dashboard' })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
